import { Form, Link, useNavigation } from "react-router";
import { useTranslation } from "react-i18next";
import { useMatchesData } from "~/utils/useMatchesData";
import { UserData } from "~/routes/_pages";
import React, { useEffect, useRef } from "react";
import { DialogWindow } from "~/utils/api.interfaces.enums";
import { MixPanel } from "~/utils/MixPanel";

declare let window: DialogWindow;
export default function TeamDropDown({}) {
  const { teams, activeTeam } = useMatchesData<UserData>("routes/_pages");
  const { t } = useTranslation("translation");
  let navigation = useNavigation();
  let isSubmitting =
    navigation.state === "submitting" || navigation.state === "loading";
  let teamDropdownRef = useRef<HTMLDetailsElement>(null);

  useEffect(() => {
    window.addEventListener("click", (e: MouseEvent) => {
      if (
        teamDropdownRef?.current &&
        !teamDropdownRef?.current?.contains(e.target as Node)
      ) {
        teamDropdownRef.current.open = false;
      }
    });
  }, []);
  return (
    <>
      <ul className={`menu p-0 mb-2 z-20 `}>
        <li>
          <details
            className={`dropdown  rounded-t-md rounded-b-[0px] w-[238px]   bg-white/[.05] border-white/10 border rounded-lg relative`}
            ref={teamDropdownRef}
          >
            <summary
              tabIndex={0}
              className={`flex flex-col  gap-1 items-start after:absolute after:right-5 after:top-6  rounded-t-md rounded-b-[0px] w-[238px]  pb-4 ${
                isSubmitting ? " cursor-not-allowed after:hidden" : ""
              } `}
            >
              <div
                tabIndex={0}
                className={`btn btn-link p-0 flex text-white font-normal no-underline text-base flex-nowrap w-full m-0`}
              >
                {activeTeam?.logoUrl ? (
                  <img
                    alt=""
                    src={activeTeam?.logoUrl}
                    className="h-8 w-8 rounded-full object-cover mr-auto"
                  />
                ) : (
                  <div className="avatar placeholder  mr-auto">
                    <div className="bg-neutral-focus text-neutral-content rounded-full w-8 mr-auto">
                      <span className="text-md">
                        {activeTeam?.nameAbbreviation}
                      </span>
                    </div>
                  </div>
                )}

                {isSubmitting && (
                  <span className="loading loading-spinner loading-sm text-secondary z-30"></span>
                )}
              </div>

              <div>
                <div
                  className={`w-[200px] whitespace-nowrap text-left mr-auto overflow-ellipsis overflow-hidden font-[700]`}
                >
                  {activeTeam?.name}
                </div>
                {activeTeam?.organization?.type === "club" && (
                  <div
                    className={`w-[200px] whitespace-nowrap text-left mr-auto overflow-ellipsis overflow-hidden`}
                  >
                    {activeTeam?.organization?.name}
                  </div>
                )}
              </div>
              <div className={`w-full  flex justify-start items-center  gap-2`}>
                {activeTeam?.organization?.type === "club" && (
                  <div className="badge text-xs py-2">
                    {" "}
                    {activeTeam?.season?.name}
                  </div>
                )}

                {!activeTeam?.isArchived && (
                  <>
                    {" "}
                    {activeTeam?.boostStatus?.boostLevel &&
                    activeTeam?.boostStatus?.boostLevel > 0 ? (
                      <div className="badge text-xs bg-black py-2 text-[#FAC99E] border-[#FAC99E]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="none"
                        >
                          <path
                            fill="#FAC99E"
                            fillRule="evenodd"
                            d="M10.342 1.857c.537-.54 1.439-.026 1.255.714l-1.09 4.382h3.056c.65 0 .991.778.551 1.26l-7.206 7.905c-.517.567-1.448.08-1.282-.672l1.122-5.073h-3.07c-.666 0-1-.81-.529-1.285l7.193-7.231Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        level {activeTeam?.boostStatus?.boostLevel}
                      </div>
                    ) : (
                      <div className="badge text-xs  bg-white/10 py-2 text-white border-transparent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="none"
                        >
                          <path
                            fill="#974DDE"
                            fillRule="evenodd"
                            d="M10.342 1.857c.537-.54 1.439-.026 1.255.714l-1.09 4.382h3.056c.65 0 .991.778.551 1.26l-7.206 7.905c-.517.567-1.448.08-1.282-.672l1.122-5.073h-3.07c-.666 0-1-.81-.529-1.285l7.193-7.231Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        level 0
                      </div>
                    )}
                  </>
                )}
                {activeTeam?.isArchived && (
                  <div className="badge badge-sm py-2  badge-outline text-gray-400">
                    {" "}
                    archived
                  </div>
                )}
              </div>
            </summary>

            <ul
              tabIndex={0}
              className="dropdown-content menu shadow bg-base-100 z-20 ml-0 rounded-b-md rounded-t-0 w-[238px]"
            >
              {teams?.map((team: any) => {
                return (
                  <li
                    key={team.id}
                    className=" "
                    onClick={() => {
                      if (document.activeElement instanceof HTMLElement) {
                        teamDropdownRef.current.open = false;
                        window.sidebar.close();
                      }
                    }}
                  >
                    <Form
                      method="post"
                      action="/action/changeteam"
                      reloadDocument={false}
                      className="text-left p-0 flex flex-row w-full"
                    >
                      <button
                        className="flex gap-2 items-center w-full text-left px-2 py-3"
                        type={"submit"}
                        name={"teamId"}
                        value={team?.id}
                        onClick={(e) => {
                          MixPanel.track("mft_common_tap_item_selectTeam");
                        }}
                      >
                        {team?.logoUrl ? (
                          <img
                            alt=""
                            src={team?.logoUrl}
                            className="h-5 w-5 rounded-full object-cover"
                          />
                        ) : (
                          <div className="avatar placeholder">
                            <div className="bg-neutral-focus text-neutral-content rounded-full w-5">
                              <span className="text-sm">
                                {team?.nameAbbreviation}
                              </span>
                            </div>
                          </div>
                        )}
                        {team.name}
                      </button>
                      {team?.organization?.type === "club" && (
                        <div className="badge badge-sm mr-1">
                          {team?.season?.name}
                        </div>
                      )}
                    </Form>
                  </li>
                );
              })}
              <li className="border-t border-gray-600 mt-2">
                <Link
                  to={"/onboarding/team-setup/what-sport"}
                  className="btn btn-ghost btn-sm font-normal text-xs flex gap-2 items-center w-full text-left justify-start px-2 mt-2"
                >
                  <svg
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill="none"
                    className="h-5 w-5 rounded-full object-cover"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9 15.75a6.75 6.75 0 100-13.5 6.75 6.75 0 000 13.5zm0 1.5A8.25 8.25 0 109 .75a8.25 8.25 0 000 16.5z"
                      fill="#BBC3CF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9 5.25a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0V6A.75.75 0 019 5.25z"
                      fill="#BBC3CF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.75 9a.75.75 0 01-.75.75H6a.75.75 0 010-1.5h6a.75.75 0 01.75.75z"
                      fill="#BBC3CF"
                    />
                  </svg>
                  Add new team
                </Link>
              </li>
            </ul>
          </details>
        </li>
      </ul>
    </>
  );
}
